<template>
  <v-container>
    <v-tabs v-model="tab">
      <v-tab v-for="(tab, index) in tabs" :key="index">
        {{ tab.label }}
      </v-tab>
      <v-tab-item v-for="(tab, index) in tabs" :key="index">
        <v-row justify="center">
          <v-col>
            <component :is="tab.component" />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import HT24 from '@/views/info/components/HT24.vue'
import Text from '@/views/info/components/Text.vue'

export default {
  data: () => {
    return {
      tab: null,
      tabs: [
        { label: 'Info', component: Text },
        { label: 'HT24', component: HT24 }
      ]
    }
  }
}
</script>
