<template>
    <v-container>
        <h1> <textloader-component textKey="StudieplanInfoRubrik"/> </h1>
        <paragraph-component> <textloader-component textKey="StudieplanInfoBrödtext"/> </paragraph-component>
    </v-container>
</template>

<script>
import ParagraphComponent from '@/components/ParagraphComponent'
import TextloaderComponent from '@/components/TextloaderComponent'
export default {
  components: {
    ParagraphComponent,
    TextloaderComponent
  }
}
</script>
